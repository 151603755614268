import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pick from "lodash/pick";
import { ProductFeedSerializer } from "web/types/serializers";

type FeedState = {
    ui: {
        isFetching: boolean;
    };
    data: Pick<
        ProductFeedSerializer,
        | "feed_items"
        | "type"
        | "pre_filters"
        | "curated_products"
        | "description"
        | "lyst_name"
        | "short_description"
        | "empty_message"
        | "empty_feed_link"
        | "feed_extension"
        | "feed_returned_filters"
    >;
};

function deduplicate(
    existingItems: FeedState["data"]["feed_items"],
    newItems: FeedState["data"]["feed_items"]
): FeedState["data"]["feed_items"] {
    const uids = existingItems.map(({ product_card: { uid } }) => uid);
    return newItems.filter(({ product_card: { uid } }) => !uids.includes(uid));
}

const initialState = {
    ui: {
        isFetching: false,
    },
    data: {
        feed_items: [],
        type: null,
        pre_filters: {},
        curated_products: [],
        description: null,
        lyst_name: null,
        short_description: null,
        empty_message: null,
        empty_feed_link: null,
        feed_extension: undefined,
        feed_returned_filters: undefined,
    },
} as FeedState;

const feedSlice = createSlice({
    name: "feed",
    initialState,
    reducers: {
        setFeedData(state, action: PayloadAction<ProductFeedSerializer>) {
            // Only set things from the ProductFeedSerializer
            // which we want to keep in the redux state
            const data = pick(
                action.payload,
                "feed_items",
                "pre_filters",
                "type",
                "empty_message",
                "empty_feed_link",
                "feed_extension",
                "feed_returned_filters"
            );
            state.data = data;
        },

        appendFeedItems(state, action: PayloadAction<ProductFeedSerializer["feed_items"]>): void {
            state.data.feed_items = [
                ...state.data.feed_items,
                ...deduplicate(state.data.feed_items, action.payload),
            ];
        },

        appendCuratedProducts(
            state,
            action: PayloadAction<ProductFeedSerializer["curated_products"]>
        ): void {
            state.data.curated_products = [
                ...(state.data.curated_products ?? []),
                ...(action.payload ?? []),
            ];
        },

        setFeedIsFetching(state, action: PayloadAction<FeedState["ui"]["isFetching"]>): void {
            state.ui.isFetching = action.payload;
        },
    },
});

export const { setFeedData, appendFeedItems, appendCuratedProducts, setFeedIsFetching } =
    feedSlice.actions;
export default feedSlice.reducer;
