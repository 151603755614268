/**
 * @file use-feed-fetch
 * @description a hook to refetch product feeds without reloading the DOM.
 */

import { useRothkoFetcher } from "web/react/hooks/use-rothko-fetcher";
import { useFeedContext } from "web/react/pages/feed/feed.context";
import { setFeedData, setFeedIsFetching } from "web/redux/ducks/feed";
import { setFeedBreadcrumbs } from "web/redux/ducks/feed-breadcrumbs";
import { setFeedCount } from "web/redux/ducks/feed-count";
import { setFeedHeader } from "web/redux/ducks/feed-header";
import { setMoreLikeThis } from "web/redux/ducks/feed-more-like-this";
import { setAll as showMoreSetAll } from "web/redux/ducks/feed-show-more";
import { setLoading as sideBarSetLoading } from "web/redux/ducks/feed-sidebar";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import { buildLanguageAwareUrlPath } from "web/script/utils/url";
import { ProductFeedSerializer } from "web/types/serializers";
/**
 * @function onGetFeedData
 * @description a function which dispatches state required by feeds.
 * @param {ProductFeedSerializer} data - an object containing the new Product Feed.
 * @returns {void}
 */

function onGetFeedData(data: ProductFeedSerializer): void {
    if (data.feed_count) {
        store.dispatch(setFeedCount(data.feed_count));
    }

    if (data.feed_navigation) {
        store.dispatch(setFeedBreadcrumbs(data.feed_navigation.shop_breadcrumbs));
    }

    if (data.show_more_button) {
        store.dispatch(showMoreSetAll(data.show_more_button));
    }

    if (data.header) {
        store.dispatch(setFeedHeader(data.header));
    }

    if (data.more_like_this_rail && data.more_like_this_rail.feed_cards.length > 0) {
        store.dispatch(setMoreLikeThis(data.more_like_this_rail));
    }

    // logging how many rows the user could see, normalised based on desktop
    analytics.event(
        "FEED",
        "POST_FILTER_ROWS",
        Math.min(Math.ceil(data.feed_items.length / 3), 4).toString()
    );

    store.dispatch(setFeedData(data));
    store.dispatch(setFeedIsFetching(false));
    store.dispatch(sideBarSetLoading(false));
}

export function useFeedFetch(): {
    fetchFeed: (url: string) => Promise<void>;
} {
    const [fetch] = useRothkoFetcher<ProductFeedSerializer>(
        "modules/product_feed",
        undefined,
        false
    );
    const { setFeedFetched } = useFeedContext();

    async function fetchFeed(url: string): Promise<void> {
        store.dispatch(setFeedIsFetching(true));
        store.dispatch(sideBarSetLoading(true));
        setFeedFetched(true);

        try {
            let languageAwareUrlPath = url;
            languageAwareUrlPath = buildLanguageAwareUrlPath(url);

            const data = await fetch({ url: languageAwareUrlPath });

            if (data) {
                onGetFeedData(data);
            }
        } catch {
            globals.window.location.reload();
        }
    }
    return { fetchFeed };
}
