import clsx from "clsx";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Divider } from "web/react/components/divider/divider";
import * as showMoreStyles from "web/react/components/feeds-show-more/feeds-show-more.css";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import { KeylineGrid, KeylineGridItem } from "web/react/components/keyline-grid";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import { PaginationProgressBar } from "web/react/components/pagination-progress-bar/pagination-progress-bar";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { useAboveFold } from "web/react/hooks/use-above-fold/use-above-fold";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useRothkoFetcher } from "web/react/hooks/use-rothko-fetcher";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { numberFormat } from "web/script/modules/formats";
import { getInitialFeedProductIds } from "web/script/utils/factories";
import {
    ProductFeedExtensionSerializer,
    ProductFeedPreFiltersSerializer,
} from "web/types/serializers";
import { FeedProductCard } from "./feed-grid";
import * as styles from "./feed-grid.css";

export function FeedExtension({
    data,
    feedType,
    mainFeedProductIds,
    feedReturnedFilters,
}: {
    data: ProductFeedExtensionSerializer;
    feedType: string;
    mainFeedProductIds: number[];
    feedReturnedFilters?: ProductFeedPreFiltersSerializer;
}): JSX.Element | null {
    const [currentData, setCurrentData] = useState(data);
    const {
        ui: { isFetching: isFetchingMainFeed },
    } = useSelector((state: ReduxStoreState) => state.feed);
    const productIds = getInitialFeedProductIds(currentData.feed_items);
    const { isDesktopViewport } = useDomViewport();
    const [fetch, isLoading] = useRothkoFetcher<ProductFeedExtensionSerializer>(
        "modules/feed_extension",
        undefined,
        false
    );
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { isVisible } = useAboveFold(containerRef, 0);

    // Send analytics for the loaded products in the extension
    useEffect(() => {
        analytics.event("feed_extension", "view", "feed_product_ids", true, {
            ids: productIds,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(productIds)]);

    // Send analytics when the user actually sees the extension
    useEffect(() => {
        if (isVisible) {
            analytics.event("feed_extension", "impression");
        }
    }, [isVisible]);

    // Whenever the data from the props changes set it again in the local data
    useEffect(() => {
        setCurrentData(data);
    }, [data]);

    // Functionality for fetching more items
    const fetchMore = useCallback(async () => {
        if (!currentData.show_more_button.show_more_next_url) {
            return;
        }

        const query = new URLSearchParams();

        query.append("url", currentData.show_more_button.show_more_next_url);
        query.append("applied_filters", JSON.stringify(feedReturnedFilters || {}));
        mainFeedProductIds.forEach((id) => {
            query.append("product_ids", id.toString());
        });

        const newData = await fetch(query);

        if (!newData) {
            // Remove the "Show more" button if no new data arrived
            setCurrentData((prev) => ({
                ...prev,
                show_more_button: {
                    ...prev.show_more_button,
                    show_more_next_url: null,
                },
            }));
        } else {
            setCurrentData((prev) => ({
                feed_items: [...(prev?.feed_items || []), ...(newData.feed_items || [])],
                show_more_button: newData.show_more_button,
            }));
        }
    }, [
        fetch,
        mainFeedProductIds,
        currentData.show_more_button?.show_more_next_url,
        feedReturnedFilters,
    ]);

    if (!currentData.feed_items.length) {
        return null;
    }

    const messageText = gettext("feed_pagination.summary_showing_products", {
        current_product_count: numberFormat(currentData.feed_items.length),
        total_product_count: numberFormat(currentData.show_more_button?.total_product_count),
    });

    return (
        <div ref={containerRef} className={styles.feedExtension}>
            <Heading
                as="h4"
                textStyle={{
                    sm: "title-2",
                    lg: isDesktopViewport ? "large-title" : "large-title-2",
                }}
                className={styles.feedExtensionTitle}
            >
                {gettext("general.similar_items")}
            </Heading>

            <Divider />

            <KeylineGrid
                className={clsx(
                    (isLoading || isFetchingMainFeed) && styles.loading,
                    styles.feedExtensionGrid
                )}
            >
                {currentData.feed_items.map(({ product_card: product }, i) => {
                    return (
                        <Fragment key={`${product.uid} ${product.link_id}`}>
                            <KeylineGridItem>
                                <FeedProductCard
                                    product={product}
                                    feedType={feedType}
                                    productPosition={i}
                                />
                            </KeylineGridItem>
                        </Fragment>
                    );
                })}
            </KeylineGrid>

            <div className={showMoreStyles.wrapper}>
                <Text
                    className={showMoreStyles.progressMessageText}
                    key={"show_more"}
                    textStyle={"small-link"}
                >
                    {messageText}
                </Text>

                <PaginationProgressBar
                    currentNumber={currentData.feed_items.length}
                    maximumNumber={currentData.show_more_button.total_product_count}
                />

                {currentData.show_more_button.show_more_next_url && (
                    <KevelCampaignControl
                        contextType={""}
                        beforeOnClick={() => {
                            analytics.event(
                                "feed_extension_show_more",
                                "click_show_more_button",
                                "click"
                            );
                        }}
                        defaultAction={fetchMore}
                        analyticsEventLabel={"feed_extension_show_more"}
                        options={{
                            action: "feeds_show_more_click",
                        }}
                    >
                        <VStack align="center" justify="center" spacing="xs">
                            <Button
                                title={gettext("general.show_more")}
                                variant={"secondary"}
                                height={"fit-content"}
                                width={"full"}
                            />

                            <div
                                className={clsx(
                                    showMoreStyles.loadingWrapper,
                                    showMoreStyles.inlineLoadingWrapper
                                )}
                            >
                                {isLoading && <LoadingSpinner />}
                            </div>
                        </VStack>
                    </KevelCampaignControl>
                )}
            </div>
        </div>
    );
}
